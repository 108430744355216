/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconYoutube = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 24 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.06 3.5H3.94A3.94 3.94 0 0 0 0 7.44v9.12a3.94 3.94 0 0 0 3.94 3.94h16.12A3.94 3.94 0 0 0 24 16.56V7.44a3.94 3.94 0 0 0-3.94-3.94zM16.54 12l-6.77 4.36a.5.5 0 0 1-.77-.42V7.28a.5.5 0 0 1 .77-.42l6.77 4.33a.5.5 0 0 1 0 .84V12z"
      />
    </svg>
  ),
);
